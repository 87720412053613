import {fetchSession, deleteSessionReq } from "@/api/sessions";
import {LOGIN_SUCCESS, UPDATE_USER} from "@/constants/actions";
import {useStore} from "vuex";

export default function useSessions() {
  const store = useStore();

  const getSession = async () => {
    const response = await fetchSession();
    if (!response) {
      return;
    }
    await store.dispatch(LOGIN_SUCCESS, response.data);
  }

  const deleteSession = async () => {
    const response = await deleteSessionReq();
    await store.dispatch(UPDATE_USER, {
      "authorities": [],
      "authenticated": false,
      "principal": null,
      "credentials": null,
    })
    return response;
  }

  return {
    getSession,
    deleteSession
  }

}