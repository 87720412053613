import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-097c97ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNav = _resolveComponent("HeaderNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SignInForm = _resolveComponent("SignInForm")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_Connection = _resolveComponent("Connection")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderNav),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.loginDialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginDialogVisible) = $event)),
        "destroy-on-close": true,
        title: "用户登录",
        center: "",
        width: "90%",
        "custom-class": "login-dialog"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SignInForm, { isOnDialog: true })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.teamsDialogVisible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.teamsDialogVisible) = $event)),
        "show-close": _ctx.teamSelected,
        "before-close": _ctx.handleTeamsDialogClose,
        title: "请选择项目组",
        top: "5vh",
        "custom-class": "teams-dialog"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table, {
            data: _ctx.teams,
            stripe: "",
            style: {"width":"100%"},
            "max-height": "380",
            "empty-text": "空数据",
            "show-header": false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, { label: "名称" }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (_ctx.switchToTeam(scope.row)),
                    class: "name-column"
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Connection)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.name), 1)
                  ], 8, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }, 8, ["modelValue", "show-close", "before-close"])
    ])
  ], 64))
}