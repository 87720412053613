
  import {defineComponent, computed} from 'vue';
  import { ElMessage, ElMessageBox } from "element-plus/es";
  import {DEFAULT_LOG_BACK_IN_PATH} from "@/constants/route";
  import SignInForm from '@/components/SignInForm.vue';
  import { onMounted } from 'vue';
  import useSessions from '@/composables/useSessions';
  import { useStore } from 'vuex'
  import useLoginHandler from "@/composables/useLoginHandler";
  import useTeams from "@/composables/useTeams";
  import {TEAMS_DIALOG_VISIBLE} from "@/constants/actions";
  import HeaderNav from "@/components/HeaderNav.vue";

  export default defineComponent({
    name: 'App',
    components: {
      SignInForm,
      HeaderNav
    },
    setup() {
      const store = useStore();
      const {getSession} = useSessions();
      const {restoreCurrentTeam, getTeams, switchToTeam} = useTeams();
      const {setLogBackInPath, setLoginDialogVisible} = useLoginHandler();

      onMounted(() => {
        restoreCurrentTeam()
        getSession()
        getTeams()
      })

      const handleTeamsDialogClose = (done: () => void) => {
        if (store.state.teamSelected) {
          store.dispatch(TEAMS_DIALOG_VISIBLE, false).then(() => done())
        }
      }

      return {
        switchToTeam,
        handleTeamsDialogClose,
        getSession,
        setLogBackInPath,
        setLoginDialogVisible,
        loginDialogVisible: computed(() => store.state.loginDialogVisible),
        teams: computed(() => store.state.teams),
        teamSelected: computed(() => store.state.teamSelected),
        teamsDialogVisible: computed(() => store.state.teamsDialogVisible)
      }
    },
    created() {
      if (this.$workbox) {
        const vm = this
        this.$workbox.addEventListener("waiting", () => {
          ElMessageBox.confirm('新版本已经准备好，是否刷新？', 'Warning', {
            confirmButtonText: '刷新',
            cancelButtonText: '暂不刷新',
            type: 'warning',
          }).then(() => {
            vm.acceptToUpdate();
          })
        });
      }

      const vm = this;
      this.axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response) {
          if (error.response.status == 401) {
            if (error.response.config.url == '/api/user') {
              return Promise.reject(error);
            }
            const currentPath = vm.$router.currentRoute.value.path;
            if (currentPath.startsWith('/notes/v/edit')
              || currentPath.startsWith('/notes/edit')
              || currentPath.startsWith('/browse')) {
              vm.setLoginDialogVisible(true)
              ElMessage({
                showClose: true,
                message: '登录信息失效',
                type: 'error',
              });
              return;
            } else if (currentPath.startsWith('/login') || currentPath == '/') {
              vm.setLogBackInPath(DEFAULT_LOG_BACK_IN_PATH);
            } else {
              vm.setLogBackInPath(currentPath);
            }
            vm.$router.push({name: 'login'});
            ElMessage({
              showClose: true,
              message: '登录信息失效',
              type: 'error',
            });
            return;
          }
        }
        return Promise.reject(error);
      });
    },
    methods: {
      async acceptToUpdate() {
        if (this.$workbox) {
          await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        }
      }
    }
  });
