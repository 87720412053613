
import { defineComponent } from 'vue';
import SignInForm from '@/components/SignInForm.vue'; // @ is an alias to /src

export default defineComponent({
    name: 'Login',
    components: {
        SignInForm,
    },
});
