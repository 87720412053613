import {LOG_BACK_IN_PATH, LOGIN_DIALOG_VISIBLE, LOGIN_SUCCESS,} from "@/constants/actions";
import {useStore} from "vuex";
import {DEFAULT_LOG_BACK_IN_PATH} from "@/constants/route";
import useTeams from "@/composables/useTeams";
import {useRouter} from "vue-router";

export default function useLoginHandler() {
  const store = useStore();
  const {getTeams} = useTeams();
  const router = useRouter();

  const setLogBackInPath = async (path: string) => {
    await store.dispatch(LOG_BACK_IN_PATH, path);
  }

  const setLoginDialogVisible = async (payload: boolean) => {
    await store.dispatch(LOGIN_DIALOG_VISIBLE, payload);
  }

  function redirectBackInPath() {
    router.push(store.state.logBackInPath);
    store.dispatch(LOG_BACK_IN_PATH, DEFAULT_LOG_BACK_IN_PATH).then(() => {return});
  }

  const loginSuccessCallback = (response: any, isOnDialog: boolean) => {
    store.dispatch(LOGIN_SUCCESS, response.data).then(() => {return});
    if (!isOnDialog) {
      if (!store.state.teamSelected) {
        getTeams().then(value => {
          if (value === 'ok') {
            redirectBackInPath();
          }
        })
      } else {
        redirectBackInPath();
      }
    }
  }

  return {
    setLogBackInPath,
    setLoginDialogVisible,
    loginSuccessCallback
  }

}