import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../pages/main/views/Home.vue'
import Login from "@/pages/main/views/Login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import(/* webpackChunkName: "signUp" */ '../pages/main/views/SignUp.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/main/views/About.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "changePassword" */ '../pages/main/views/ChangePassword.vue')
  },
  {
    path: '/browse',
    name: 'browse',
    component: () => import(/* webpackChunkName: "browse" */ '../pages/main/views/browse/Index.vue')
  },
  {
    path: '/folders/:parentFolderId?',
    name: 'folders',
    component: () => import(/* webpackChunkName: "folders" */ '../pages/main/views/folders/Index.vue')
  },
  {
    path: '/trash/:parentFolderId?',
    name: 'trash',
    component: () => import(/* webpackChunkName: "trash" */ '../pages/main/views/trash/Index.vue')
  },
  {
    path: '/notes/new/:parentId',
    name: 'newNote',
    component: () => import(/* webpackChunkName: "newNote" */ '../pages/main/views/notes/New.vue')
  },
  {
    path: '/notes/edit/:noteId',
    name: 'editNote',
    component: () => import(/* webpackChunkName: "editNote" */ '../pages/main/views/notes/Edit.vue')
  },
  {
    path: '/notes/v/edit/:noteId',
    name: 'editNoteByVditor',
    component: () => import(/* webpackChunkName: "editNote" */ '../pages/main/views/vditor-notes/Edit.vue')
  },
  {
    path: '/notes/show/:noteId',
    name: 'showNote',
    component: () => import(/* webpackChunkName: "showNote" */ '../pages/main/views/notes/Show.vue')
  },
  {
    path: '/notes/v/show/:noteId',
    name: 'showNoteByVditor',
    component: () => import(/* webpackChunkName: "showNote" */ '../pages/main/views/vditor-notes/Show.vue')
  },
  {
    path: '/note-histories/:noteId',
    name: 'noteHistories',
    component: () => import(/* webpackChunkName: "noteHistories" */ '../pages/main/views/note-histories/Index.vue')
  },
  {
    path: '/shared/notes/:noteId',
    name: 'sharedShowNote',
    component: () => import(/* webpackChunkName: "sharedShowNote" */ '../pages/shared/views/notes/Show.vue')
  },
  {
    path: '/shared/notes/v/:noteId',
    name: 'sharedShowNoteByVditor',
    component: () => import(/* webpackChunkName: "sharedShowNoteByVditor" */ '../pages/shared/views/vditor-notes/Show.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
