import {CURRENT_TEAM, TEAM_SELECTED, TEAMS, TEAMS_DIALOG_VISIBLE} from "@/constants/actions";
import {Store, useStore} from "vuex";
import {fetchTeams} from "@/api/teams";
import {getCookie, setCookie} from "@/utils/cookie";
import {useRouter} from "vue-router";
import {AxiosResponse} from "axios";

export default function useTeams() {
  const store = useStore();
  const router = useRouter();

  const restoreCurrentTeam = async () => {
    const currentTeamId = getCookie('currentTeamId')
    if (currentTeamId) {
      await store.dispatch(CURRENT_TEAM, {
        id: Number.parseInt(currentTeamId)
      });
    }
  }

  const getTeams = async () => {
    const response = await fetchTeams();
    return await dispatchTeams(store, response)
  }

  const dispatchTeams = async (context: Store<any>, response: AxiosResponse<any, any>) => {
    if (!response || !response.data) {
      return 'error';
    }
    const teams: any[] = response.data.teams;
    await context.dispatch(TEAMS, teams);
    if (!teams || teams.length < 1) {
      return "ok";
    }
    const currentTeamId = getCookie('currentTeamId')
    if (currentTeamId) {
      const team = teams.find((v: any) => v.id === Number.parseInt(currentTeamId))
      if (team) {
        await context.dispatch(CURRENT_TEAM, team);
        await context.dispatch(TEAM_SELECTED, true);
        return "ok";
      }
    }

    await context.dispatch(TEAM_SELECTED, false);
    await context.dispatch(TEAMS_DIALOG_VISIBLE, true);
    return "waiting_for_selecting"
  }

  const switchTeam = async () => {
    await store.dispatch(TEAMS_DIALOG_VISIBLE, true);
  }

  const switchToTeam = async (team: any) => {
    await store.dispatch(CURRENT_TEAM, team).then(async () => {
      setCookie('currentTeamId', team.id)
      await store.dispatch(TEAM_SELECTED, true);
      await store.dispatch(TEAMS_DIALOG_VISIBLE, false);
      await router.replace({name: "browse", params: {teamId: team.id}})
      window.location.reload();
    });
  }

  return {
    restoreCurrentTeam,
    getTeams,
    switchTeam,
    switchToTeam
  }

}