export function setCookie(key: string, value: any) {
  document.cookie = key + "=" + escape(value) + ";path=/";
}

export function getCookie(key: string) {
  const reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
  const arr = document.cookie.match(reg)
  if (arr)
    return unescape(arr[2]);
  else
    return null;
}