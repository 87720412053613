import {createLogger, createStore} from 'vuex'
import {
  LOG_BACK_IN_PATH,
  LOGIN_DIALOG_VISIBLE,
  TEAMS,
  UPDATE_USER,
  CURRENT_TEAM,
  TEAM_SELECTED,
  TEAMS_DIALOG_VISIBLE
} from "@/constants/actions";
import {User} from "@/types/user";
import {DEFAULT_LOG_BACK_IN_PATH} from "@/constants/route";

export default createStore({
  state: {
    user: {
      authorities: [],
      authenticated: false,
      principal: null,
      credentials: null,
    } as User,
    teams: [],
    currentTeam: null,
    teamSelected: false,
    teamsDialogVisible: false,
    loginDialogVisible: false,
    logBackInPath: DEFAULT_LOG_BACK_IN_PATH
  },
  mutations: {
    updateUser(state, user) {
      state.user = user
    },
    teams(state, payload) {
      state.teams = payload
    },
    currentTeam(state, payload) {
      state.currentTeam = payload
    },
    teamSelected(state, payload) {
      state.teamSelected = payload
    },
    teamsDialogVisible(state, payload) {
      state.teamsDialogVisible = payload
    },
    loginDialogVisible(state, visible) {
      state.loginDialogVisible = visible
    },
    logBackInPath(state, payload) {
      state.logBackInPath = payload
    },
  },
  actions: {
    updateUser({commit}, payload) {
      commit(UPDATE_USER, payload)
    },
    teams({commit}, payload) {
      commit(TEAMS, payload)
    },
    currentTeam({commit}, payload) {
      commit(CURRENT_TEAM, payload)
    },
    teamSelected({commit}, payload) {
      commit(TEAM_SELECTED, payload)
    },
    teamsDialogVisible({commit}, payload) {
      commit(TEAMS_DIALOG_VISIBLE, payload)
    },
    loginDialogVisible({commit}, payload) {
      commit(LOGIN_DIALOG_VISIBLE, payload)
    },
    async loginSuccess({commit, dispatch}, payload) {
      commit(UPDATE_USER, payload)
      await dispatch(LOGIN_DIALOG_VISIBLE, false)
    },
    logBackInPath({commit}, payload) {
      commit(LOG_BACK_IN_PATH, payload)
    },
  },
  modules: {
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
