import { createApp } from 'vue'
import App from './App.vue'
import wb from "../../registerServiceWorker";
import router from '../../router'
import store from '../../store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
app.config.globalProperties.$workbox = wb
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
