<template>
  <div class="sign-in">
    <div class="sign-in-form">
      <el-form
              ref="ruleForm"
              :model="ruleForm"
              status-icon
              :rules="rules"
              label-width="60px"
              class="demo-ruleForm"
      >
        <el-form-item label="用户名:" prop="userName">
          <el-input
                  v-model="ruleForm.userName"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="pass">
          <el-input
                  v-model="ruleForm.pass"
                  type="password"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
          >登录
          </el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {ElMessage} from "element-plus";
  import useLoginHandler from "@/composables/useLoginHandler";
  import {USER} from "@/constants/api";

  export default {
    name: 'SignInForm',
    props: ['isOnDialog'],
    data() {
      const validateUserName = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入用户名'))
        }
        callback()
      }
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        }
        callback()
      }
      return {
        ruleForm: {
          userName: '',
          pass: ''
        },
        rules: {
          userName: [{ validator: validateUserName, trigger: 'blur' }],
          pass: [{ validator: validatePass, trigger: 'blur' }]
        },
      }
    },
    setup() {
      const {loginSuccessCallback} = useLoginHandler();

      return {
        loginSuccessCallback
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const vm = this;
            this.axios.get(USER, {
              auth: {
                username: this.ruleForm.userName,
                password: this.ruleForm.pass
              }
            }).then(function (response) {
              ElMessage({
                showClose: true,
                message: '登录成功',
                type: 'success',
              });
              vm.loginSuccessCallback(response, vm.isOnDialog)
            }).catch(function (error) {
              const data = error.response.data;
              if (data) {
                ElMessage({
                  showClose: true,
                  message: data.error,
                  type: 'error',
                });
              } else {
                ElMessage({
                  showClose: true,
                  message: '登录失败',
                  type: 'error',
                });
              }
              // console.log(error);
            });
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .sign-in {
  }
  .sign-in-form {
    margin: 0 auto;
    padding: 8px 16px;
    max-width: 640px;
  }
</style>