
  import { User } from '@/types/user';
import {computed, defineComponent} from 'vue';
  import {useStore} from "vuex";

  export default defineComponent({
    name: 'Home',
    mounted() {
      this.loginCheck(this.loginUser);
    },
    setup() {
      const store = useStore();
      const loginUser = computed(() => store.state.user);

      return {
        loginUser
      }
    },
    watch: {
      loginUser(value, old) {
        this.loginCheck(value);
      }
    },
    methods: {
      loginCheck(loginUser: User) {
        if (loginUser.authenticated) {
          this.$router.push({name: 'browse'});
        }
      },
      login() {
        this.$router.push('/login');
      },
      signUp() {
        this.$router.push('/sign-up');
      }
    }
  });
